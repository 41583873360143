import React from 'react';
import styled from '@emotion/styled';
import { borderRadius, Colors, mediaQueryMax, times8 } from 'Constants/Styles';
import TabComponent from 'Components/Primitives/Tabs/TabComponent';
import TabDropdown from 'Components/Primitives/Tabs/TabDropdown';
import { Tab } from 'Components/Primitives/Tabs/Tabs.types';

export interface TabsProps {
  activeTabKey?: string;
  tabs: Tab[];
  direction?: TabsDirection;
  className?: string;
  'data-testid'?: string;
  onTabClick?(key: string): void;
}

export interface TabItemProps {
  tab: Tab;
  isActive: boolean;
  onTabClick?(key: string): void;
}

type TabsDirection = 'horizontal' | 'vertical';

const StyledTabsHorizontal = styled.nav`
  display: flex;
  border-bottom: 1px solid ${Colors.Border};
  margin: 0 0 ${times8(5)}px;
  .tab {
    display: block;
    cursor: pointer;
    padding: 0 ${times8(2)}px ${times8(1.5)}px;
    margin-right: ${times8(2)}px;
    color: ${Colors.SubText};
    line-height: 1.3;
    display: inline-block;
    &:hover {
      text-decoration: none;
    }
    &:last-of-type {
      margin-right: 0;
    }
    &.active {
      font-weight: 500;
      color: initial;
      border-bottom: 2px solid ${Colors.Text};
    }
  }

  ${mediaQueryMax.medium} {
    margin: 0 0 ${times8(4)}px;
    .tab {
      padding: 0 ${times8()}px ${times8()}px;
    }
  }
`;

const StyledTabsVertical = styled.nav`
  display: flex;
  flex-direction: column;
  .tab {
    min-width: 150px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: ${times8()}px;
    margin-bottom: ${times8(0.5)}px;
    color: ${Colors.SubText};
    border-radius: ${borderRadius}px;
    &:hover {
      text-decoration: none;
    }
    &.active {
      color: ${Colors.Primary};
      background-color: ${Colors.Primary}1c;
    }
  }
  .tab-icon {
    margin-right: ${times8()}px;
    width: ${times8(3)}px;
    height: ${times8(3)}px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .tab-expand {
    position: relative;
    margin-bottom: 0;
    user-select: none;
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-bottom: 1px solid;
      border-right: 1px solid;
      transform: rotate(45deg);
      right: 8px;
      top: 13px;
      color: ${Colors.SubText};
    }
    &.expand {
      &:after {
        top: 17px;
        transform: rotate(225deg);
      }
    }
  }
  .tab-children {
    margin-top: 4px;
    .tab {
      margin-left: ${times8(2)}px;
    }
  }
`;

const TabContent: React.FC<TabsProps> = ({ tabs, activeTabKey, onTabClick }) => {
  return (
    <>
      {tabs.map((tab) =>
        tab.children ? (
          <TabDropdown tab={tab} activeTabKey={activeTabKey} key={tab.key} />
        ) : (
          <TabComponent key={tab.key} tab={tab} isActive={activeTabKey === tab.key} onTabClick={onTabClick} />
        )
      )}
    </>
  );
};

export const Tabs: React.FC<TabsProps> = ({
  tabs,
  activeTabKey,
  direction,
  className,
  'data-testid': dataTestId,
  onTabClick
}) => {
  return direction === 'vertical' ? (
    <StyledTabsVertical data-testid={dataTestId} className={className}>
      <TabContent tabs={tabs} activeTabKey={activeTabKey} onTabClick={onTabClick} />
    </StyledTabsVertical>
  ) : (
    <StyledTabsHorizontal data-testid={dataTestId} className={className}>
      <TabContent tabs={tabs} activeTabKey={activeTabKey} onTabClick={onTabClick} />
    </StyledTabsHorizontal>
  );
};
