import * as React from "react";
const SvgSupport = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "support", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { strokeLinecap: "round", d: "M 269.389 365.22 C 308.8 352.35 351.283 352.35 390.694 365.22 M 269.389 526.961 C 308.8 514.091 351.283 514.091 390.694 526.961 M 845.914 215.61 L 851.898 218.279 C 866.505 224.768 875.919 239.253 875.917 255.237 L 875.917 813.646 C 875.92 842.907 845.805 862.484 819.065 850.604 L 784.937 835.481 C 727.016 809.739 660.9 809.739 602.979 835.481 L 568.852 850.645 C 542.111 862.525 511.996 842.948 512 813.687 L 512 255.237", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 62
} }), /* @__PURE__ */ React.createElement("path", { d: "M 527.163 176.388 L 562.544 148.083 C 594.261 122.708 636.306 114.269 675.358 125.44 L 725.296 139.713 C 742.642 144.675 754.603 160.529 754.611 178.571 L 754.611 700.994 C 754.626 727.873 728.898 747.284 703.056 739.893 L 683.85 734.393 C 641.946 722.412 597.172 739.613 574.068 776.567 L 562.544 795.046 C 551.429 812.913 525.141 812.048 515.225 793.489 C 513.113 789.536 512.006 785.125 512 780.643 L 512 813.646 C 512.004 842.907 481.889 862.484 455.148 850.604 L 421.021 835.481 C 363.1 809.739 296.984 809.739 239.062 835.481 L 204.935 850.645 C 178.195 862.525 148.08 842.948 148.083 813.687 L 148.083 255.237 C 148.081 239.253 157.495 224.768 172.102 218.279 L 239.062 188.519 C 296.984 162.776 363.1 162.776 421.021 188.519 L 487.981 218.279 C 502.585 224.767 511.998 239.247 512 255.227 L 512 207.927 C 511.995 195.644 517.575 184.025 527.163 176.348 Z", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 62
} }));
export default SvgSupport;
