import styled from '@emotion/styled';
import { Layout, times8 } from 'Constants/Styles';

export const StyledSalesforceApp = styled.div`
  position: relative;
  min-height: 100vh;
  background-color: ${Layout.backgroundColor};
  main {
    padding: ${times8(2)}px;
    position: relative;
    z-index: 1;
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    max-width: 1800px;
  }
`;
