export enum Icons {
  Staircase = 'Staircase',
  Home = 'Home',
  HomeFull = 'HomeFull',
  Search = 'Search',
  EmailSearch = 'EmailSearch',
  EmailSearchFull = 'EmailSearchFull',
  EmailSend = 'EmailSend',
  Cogwheel = 'Cogwheel',
  CogwheelFull = 'CogwheelFull',
  Save = 'Save',
  Edit = 'Edit',
  ChevronDown = 'ChevronDown',
  ChevronUp = 'ChevronUp',
  ChevronRight = 'ChevronRight',
  ChevronLeft = 'ChevronLeft',
  User = 'User',
  Users = 'Users',
  UsersFull = 'UsersFull',
  UserAdd = 'UserAdd',
  UserAddFull = 'UserAddFull',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ArrowTwoDirections = 'ArrowTwoDirections',
  Calendar = 'Calendar',
  CalendarMinimal = 'CalendarMinimal',
  Close = 'Close',
  CloseRound = 'CloseRound',
  CloseSquare = 'CloseSquare',
  InfoCircle = 'InfoCircle',
  Warning = 'Warning',
  WarningFilled = 'WarningFilled',
  Email = 'Email',
  Minus = 'Minus',
  MinusCircle = 'MinusCircle',
  Plus = 'Plus',
  PlusSquare = 'PlusSquare',
  PlusSquareFull = 'PlusSquareFull',
  Logout = 'Logout',
  Columns = 'Columns',
  Filter = 'Filter',
  CheckCircleFilled = 'CheckCircleFilled',
  SentimentOutline = 'SentimentOutline',
  SentimentPositive = 'SentimentPositive',
  SentimentNegative = 'SentimentNegative',
  SentimentNeutral = 'SentimentNeutral',
  MailOpen = 'MailOpen',
  MailClosed = 'MailClosed',
  TicketsOpen = 'TicketsOpen',
  TicketsClosed = 'TicketsClosed',
  Trash = 'Trash',
  LoadingCircleSpinner = 'LoadingCircleSpinner',
  StairsUp = 'StairsUp',
  StairsDown = 'StairsDown',
  StairsLevel = 'StairsLevel',
  V = 'V',
  Video = 'Video',
  Chat = 'Chat',
  ChatAlert = 'ChatAlert',
  Reports = 'Reports',
  ReportsFull = 'ReportsFull',
  DocOpen = 'DocOpen',
  DocFolded = 'DocFolded',
  DocPDF = 'DocPDF',
  InfoSquare = 'InfoSquare',
  Download = 'Download',
  Bubbles = 'Bubbles',
  MoreHorizontal = 'MoreHorizontal',
  MoreVertical = 'MoreVertical',
  ActivityOutbound = 'ActivityOutbound',
  ActivityInbound = 'ActivityInbound',
  Time = 'Time',
  UploadFile = 'UploadFile',
  Integrate = 'Integrate',
  IntegrateCancel = 'IntegrateCancel',
  Customer = 'Customer',
  CustomerFull = 'CustomerFull',
  CustomerAdd = 'CustomerAdd',
  CustomerAddFull = 'CustomerAddFull',
  SandClock = 'SandClock',
  Layers = 'Layers',
  Dollar = 'Dollar',
  Journey = 'Journey',
  EventTicket = 'EventTicket',
  Copy = 'Copy',
  DragNDrop = 'DragNDrop',
  DataSyncOutline = 'DataSyncOutline',
  DataSyncFull = 'DataSyncFull',
  DataSyncPositive = 'DataSyncPositive',
  DataSyncNegative = 'DataSyncNegative',
  Notification = 'Notification',
  NotificationAlert = 'NotificationAlert',
  NotificationConfig = 'NotificationConfig',
  SearchCommunication = 'SearchCommunication',
  SearchCommunicationFull = 'SearchCommunicationFull',
  Folder = 'Folder',
  Heart = 'Heart',
  WarningCircle = 'WarningCircle',
  CheckSquare = 'CheckSquare',
  CheckSquareFull = 'CheckSquareFull',
  SyncNow = 'SyncNow',
  GoTo = 'GoTo',
  Link = 'Link',
  LinkCopy = 'LinkCopy',
  Briefcase = 'Briefcase',
  PulseSquare = 'PulseSquare',
  PulseSquareFull = 'PulseSquareFull',
  Relationship = 'Relationship',
  Adjust = 'Adjust',
  Megaphone = 'Megaphone',
  MegaphoneFull = 'MegaphoneFull',
  Support = 'Support',
  ResetPosition = 'ResetPosition',
  Paragraph = 'Paragraph',
  Bold = 'Bold',
  OrderedList = 'OrderedList',
  UnorderedList = 'UnorderedList',
  Underline = 'Underline',
  Italic = 'Italic',
  H1 = 'H1',
  H2 = 'H2'
}
