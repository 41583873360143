import React, { MouseEvent, ReactNode, useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import { Colors, mediaQueryMax, times8 } from 'Constants/Styles';
import ReactModal from 'react-modal';
import { Button, ButtonProps } from 'Components/Primitives/Button/Button';
import { Icons } from 'Components/Primitives/Icon/Icon.types';
import { Icon } from 'Components/Primitives/Icon/Icon';

export interface ModalProps {
  isOpen: boolean;
  title: ReactNode;
  subtitle?: ReactNode;
  size?: 'small' | 'middle' | 'large' | 'full';
  buttonConfig?: ModalButtonConfig;
  children?: ReactNode;
  hideCloseButton?: boolean;
  'data-testid'?: string;
  onClose(): void;
  onAfterClose?(): void;
}

export interface ModalButtonConfig {
  ok: ButtonConfig;
  cancel?: ButtonConfig;
  secondary?: ButtonConfig;
}

interface ButtonConfig {
  label: string;
  buttonProps?: ButtonProps;
  dataTestId?: string;
  action?(e: MouseEvent): void;
}

const StyledModalContent = styled.div`
  position: relative;

  .modal-content-wrapper {
    padding: 0 ${times8(4)}px;
    position: relative;
    display: flex;
    min-height: 120px;
  }

  .close-modal,
  .title,
  .modal-image-content,
  .actions {
    align-self: flex-start;
    position: sticky;
    background-color: white;
    z-index: 2;
  }

  .modal-image-content,
  .title {
    top: 0;
  }

  .title {
    padding: ${times8(3)}px 0;
    h2 {
      font-size: 24px;
      white-space: pre-line;
      line-height: 1.1;
    }
  }

  .subtitle {
    color: ${Colors.SubText};
    white-space: pre-line;
    margin-top: ${times8(0.5)}px;
  }

  .modal-title-and-content {
    flex-grow: 1;
  }

  .modal-image-content {
    flex-shrink: 0;
    padding: ${times8(3)}px 0 0;
    margin-left: ${times8(4)}px;
    img {
      height: 450px;
    }
  }

  .close-modal {
    top: 4px;
    flex-shrink: 0;
    display: block;
    width: ${times8(4)}px;
    height: ${times8(4)}px;
    margin-right: -${times8(3)}px;
    font-size: 16px;
  }

  .actions {
    bottom: 0;
    margin-top: ${times8(2)}px;
    padding: ${times8(2)}px ${times8(4)}px ${times8(4)}px;
    border-top: 1px solid ${Colors.Border};
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .main-actions {
    display: flex;
    justify-content: flex-end;
  }

  .modal-ok-button {
    margin-left: ${times8()}px;
  }

  ${mediaQueryMax.small} {
    .modal-content-wrapper {
      padding: 0 ${times8(2)}px;
    }
    .close-modal {
      width: 22px;
      height: 22px;
      margin-right: -12px;
    }
    .actions {
      padding: ${times8(2)}px;
    }
  }
`;

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  title,
  subtitle,
  size,
  buttonConfig,
  hideCloseButton = false,
  'data-testid': dataTestId,
  onClose,
  onAfterClose,
  children
}) => {
  const onOkClick = useCallback(
    (e: MouseEvent) => {
      buttonConfig?.ok?.action?.(e);
    },
    [buttonConfig]
  );

  const onCancelClick = useCallback(
    (e: MouseEvent) => {
      if (typeof buttonConfig?.cancel?.action === 'function') {
        buttonConfig?.cancel?.action?.(e);
      } else {
        onClose();
      }
    },
    [buttonConfig, onClose]
  );

  const onSecondaryClick = useCallback(
    (e: MouseEvent) => {
      buttonConfig?.secondary?.action?.(e);
    },
    [buttonConfig]
  );

  useEffect(() => {
    if (isOpen && document.body.scrollHeight > document.body.clientHeight) {
      document.body.classList.add('has-scrollbar');
    }
  }, [isOpen]);

  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      className={`modal-content${size ? ` ${size}` : ''}`}
      overlayClassName="modal-overlay"
      onRequestClose={onClose}
      closeTimeoutMS={200}
      onAfterClose={() => {
        document.body.classList.remove('has-scrollbar');
        onAfterClose && onAfterClose();
      }}
    >
      <StyledModalContent data-testid={dataTestId || 'modal-content'}>
        <div className="modal-content-wrapper">
          <div className="modal-title-and-content">
            <div className="title">
              <>
                {typeof title === 'string' ? <h2>{title}</h2> : title}
                {subtitle && <p className="subtitle">{subtitle}</p>}
              </>
            </div>

            {children}
          </div>

          {!hideCloseButton && (
            <button className="close-modal" onClick={onClose} type="button">
              <Icon icon={Icons.Close} />
            </button>
          )}
        </div>

        {buttonConfig && (
          <div className="actions">
            <div className="secondary-action">
              {buttonConfig.secondary && (
                <Button
                  type="link"
                  {...buttonConfig.secondary.buttonProps}
                  data-testid={buttonConfig.secondary.dataTestId || 'modal-secondary-button'}
                  onClick={onSecondaryClick}
                >
                  {buttonConfig.secondary.label}
                </Button>
              )}
            </div>
            <div className="main-actions">
              {buttonConfig.cancel && (
                <Button
                  type="default"
                  {...buttonConfig.cancel.buttonProps}
                  data-testid={buttonConfig.cancel.dataTestId || 'modal-cancel-button'}
                  onClick={onCancelClick}
                >
                  {buttonConfig.cancel.label}
                </Button>
              )}
              <Button
                type="primary"
                {...buttonConfig.ok.buttonProps}
                data-testid={buttonConfig.ok.dataTestId || 'modal-ok-button'}
                onClick={onOkClick}
                className="modal-ok-button"
              >
                {buttonConfig.ok.label}
              </Button>
            </div>
          </div>
        )}
      </StyledModalContent>
    </ReactModal>
  );
};
