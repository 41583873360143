import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUsersEssentialsQuery, useUsersMap } from 'hooks';
import { UserEssentials } from 'Types/User.types';
import { TreeSelect, TreeNode, TreeSelectValue, SelectProps } from 'Components/Primitives';
import { getSelectedUsers, getUsersTreeSelectOptions } from 'Components/Users/UsersTreeSelect/UsersTreeSelectUtils';
import { TreeSelectProps } from 'antd';

interface UsersTreeSelectProps {
  value?: TreeSelectValue;
  placeholder?: string;
  mode?: SelectProps['mode'];
  disabled?: boolean;
  emptyAsAll?: boolean;
  allowClear?: boolean;
  maxTagCount?: number | 'responsive';
  userSelectType?: 'all' | 'managers';
  'data-testid'?: string;
  onChange?(ids: TreeSelectValue): void;
  getPopupContainer?: TreeSelectProps['getPopupContainer'];
  onSelect?(users: UserEssentials | UserEssentials[] | null): void;
}

export const UsersTreeSelect: React.FC<UsersTreeSelectProps> = ({
  value,
  placeholder,
  mode = 'multiple',
  disabled,
  emptyAsAll = true,
  allowClear,
  maxTagCount,
  userSelectType = 'all',
  'data-testid': dataTestId,
  onChange,
  getPopupContainer,
  onSelect
}) => {
  const { t } = useTranslation(['common']);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [treeData, setTreeData] = useState<TreeNode[]>([]);

  const { data, isLoading } = useUsersEssentialsQuery();
  const usersMap = useUsersMap();

  useEffect(() => {
    if (!data) return;
    const options = getUsersTreeSelectOptions(data.data, searchTerm, userSelectType);
    setTreeData(options);
  }, [data, searchTerm, userSelectType]);

  const onTreeSelectChange = useCallback(
    (e: TreeSelectValue) => {
      onChange && onChange(e);
      onSelect && onSelect(getSelectedUsers(e, usersMap));
    },
    [onChange, onSelect, usersMap]
  );

  const onSearch = useCallback((q: string) => {
    setSearchTerm(q);
  }, []);

  return (
    <TreeSelect
      options={treeData}
      value={value && treeData.length > 0 ? value : undefined}
      placeholder={placeholder || t('user-select.placeholder')}
      selectMultiple={mode === 'multiple'}
      disabled={disabled}
      emptyAsAll={emptyAsAll}
      allowClear={allowClear}
      loading={isLoading}
      maxTagCount={maxTagCount}
      data-testid={dataTestId || 'user-select'}
      onChange={onTreeSelectChange}
      getPopupContainer={getPopupContainer}
      onSearch={onSearch}
    />
  );
};
