import * as React from "react";
const SvgAdjust = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "64 64 896 896", focusable: "false", "data-icon": "adjust", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("defs", null), /* @__PURE__ */ React.createElement("line", { style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60,
  strokeLinecap: "round"
}, x1: 451.06, y1: 243.825, x2: 860.866, y2: 243.547 }), /* @__PURE__ */ React.createElement("line", { style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60,
  strokeLinecap: "round"
}, x1: 163.133, y1: 243.825, x2: 228.139, y2: 243.989 }), /* @__PURE__ */ React.createElement("line", { style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60,
  strokeLinecap: "round"
}, x1: 776.06, y1: 515.264, x2: 860.866, y2: 514.986 }), /* @__PURE__ */ React.createElement("line", { style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60,
  strokeLinecap: "round"
}, x1: 163.133, y1: 515.264, x2: 536.939, y2: 514.986 }), /* @__PURE__ */ React.createElement("line", { style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60,
  strokeLinecap: "round"
}, x1: 530.133, y1: 786.424, x2: 860.866, y2: 788.182 }), /* @__PURE__ */ React.createElement("line", { style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: 60,
  strokeLinecap: "round"
}, x1: 163.133, y1: 786.424, x2: 287.866, y2: 788.182 }), /* @__PURE__ */ React.createElement("ellipse", { style: {
  stroke: "currentColor",
  strokeWidth: 60,
  fill: "none"
}, cx: 399.288, cy: 787.303, rx: 104.235, ry: 104.235 }), /* @__PURE__ */ React.createElement("ellipse", { style: {
  stroke: "currentColor",
  strokeWidth: 60,
  fill: "none"
}, cx: 336.974, cy: 243.686, rx: 104.235, ry: 104.235 }), /* @__PURE__ */ React.createElement("ellipse", { style: {
  stroke: "currentColor",
  strokeWidth: 60,
  fill: "none"
}, cx: 649.565, cy: 515.125, rx: 104.235, ry: 104.235 }));
export default SvgAdjust;
