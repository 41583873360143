import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import 'Style/App.scss';
import * as Sentry from '@sentry/react';
import { productionEnv } from 'Utils/environment';
import FullPageSpinner from 'Components/Styled/FullPageSpinner';
import { backendApi } from 'http/Request';
import { LoggedInUser } from 'Types/Auth.types';
import SalesforceUnauthorisedError, {
  SalesforceUnauthorisedErrorType
} from 'Views/SalesforceApp/SalesforceUnauthorisedError';
import { SalesforceQueryParams } from 'Views/SalesforceApp/SalesforceApp.types';
import { lazyWithRetries } from 'Utils/lazyWithRetries';

const SalesforceAppAuthenticated = lazyWithRetries(() => import('Views/SalesforceApp/SalesforceAppAuthenticated'));

if (productionEnv) {
  Sentry.init({
    dsn: 'https://935eaba1e02f4ab380fe7b40a182fb16@o508855.ingest.sentry.io/5602205',
    integrations: [Sentry.browserTracingIntegration()],
    denyUrls: ['localhost'],
    tracesSampleRate: 1.0
  });
}

const SalesforceAppEntry: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [params] = useState<SalesforceQueryParams>({
    sessionId: searchParams.get('sessionId'),
    orgId: searchParams.get('orgId'),
    userId: searchParams.get('userId'),
    accountId: searchParams.get('accountId')
  });
  const [authenticateStatus, setAuthenticateStatus] = useState<'authenticated' | SalesforceUnauthorisedErrorType>();
  const [customerId, setCustomerId] = useState<number>();

  useEffect(() => {
    if (!Object.values(params).every((p) => typeof p === 'string')) {
      Sentry.captureException(`Salesforce params missing ${JSON.stringify(params)}`);
      setAuthenticateStatus('missingParams');
      return;
    }
    backendApi
      .post<{ customerId: number | null }>('/salesforce/auth', params)
      .then((res) => {
        if (res.data.customerId === null) {
          setAuthenticateStatus('accountDoesNotExist');
        } else {
          setCustomerId(res.data.customerId);
        }
      })
      .catch((error) => {
        setAuthenticateStatus('unauthorised');
        Sentry.captureException('salesforce auth error', error);
      });
  }, [params]);

  useEffect(() => {
    if (customerId === undefined) return;
    backendApi
      .get<LoggedInUser>('/users/me')
      .then((res) => {
        if (res.data.id) {
          setAuthenticateStatus('authenticated');
        } else {
          setAuthenticateStatus('unauthorised');
        }
      })
      .catch((error) => {
        setAuthenticateStatus('unauthorised');
        Sentry.captureException('salesforce logged in user error', error);
      });
  }, [customerId]);

  return authenticateStatus === undefined ? (
    <FullPageSpinner />
  ) : authenticateStatus !== 'authenticated' ? (
    <SalesforceUnauthorisedError type={authenticateStatus} />
  ) : (
    <SalesforceAppAuthenticated customerId={customerId} />
  );
};

export default SalesforceAppEntry;
