import { ColorPalette, Colors } from 'Constants/Styles';

export const lineSizeByPropSize = {
  small: 60,
  middle: 100,
  large: 300
};

export const RAIL_COLOR = ColorPalette.plainGray20;
export const PROGRESS_COLOR = Colors.Primary;
