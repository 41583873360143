import * as React from "react";
const SvgParagraph = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "64 64 896 896", focusable: "false", "data-icon": "paragraph", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("line", { style: {
  fill: "none",
  strokeWidth: 70,
  stroke: "currentColor",
  strokeLinecap: "round"
}, x1: 387.734, y1: 281.322, x2: 839.203, y2: 281.322 }), /* @__PURE__ */ React.createElement("line", { style: {
  fill: "none",
  strokeWidth: 70,
  stroke: "currentColor",
  strokeLinecap: "round"
}, x1: 184.797, y1: 435.107, x2: 839.203, y2: 435.107 }), /* @__PURE__ */ React.createElement("line", { style: {
  fill: "none",
  strokeWidth: 70,
  stroke: "currentColor",
  strokeLinecap: "round"
}, x1: 184.797, y1: 742.678, x2: 593.758, y2: 742.678 }), /* @__PURE__ */ React.createElement("line", { style: {
  fill: "none",
  strokeWidth: 70,
  stroke: "currentColor",
  strokeLinecap: "round"
}, x1: 184.797, y1: 588.894, x2: 839.203, y2: 588.894 }));
export default SvgParagraph;
