import { FieldMetadata, FieldType } from 'Types/Fields.types';
import { FormFieldMetadata, FormFieldType } from 'Components/FormGenerator/FormGenerator.types';
import { camelCaseToKebabCase } from 'Utils/functional';
import { Rule } from 'antd/es/form';
import { translate } from 'Utils/translate';
import get from 'lodash/get';
import setWith from 'lodash/setWith';
import { getQueryStringDate } from 'Utils/date';

export const convertFormDataToQueryData = <T extends object, R extends object>(
  formData: T,
  formMetata: FormFieldMetadata[]
): R => {
  return formMetata.reduce((acc: R, fmd) => {
    const val = get(formData, fmd.id);
    setWith(
      acc,
      fmd.id.toString(),
      val && fmd.fieldType === FormFieldType.Date ? getQueryStringDate(val) : val,
      Object
    );
    return acc;
  }, {} as R);
};

const fieldTypeToFormFieldType: Record<FieldType, FormFieldType> = {
  [FieldType.String]: FormFieldType.String,
  [FieldType.Number]: FormFieldType.Number,
  [FieldType.Boolean]: FormFieldType.Boolean,
  [FieldType.Date]: FormFieldType.Date,
  [FieldType.DateTime]: FormFieldType.DateTime,
  [FieldType.UserReference]: FormFieldType.TreeList,
  [FieldType.List]: FormFieldType.List
};

export const fieldMetadataToFormMetadata = (fieldMetadata: FieldMetadata): FormFieldMetadata => {
  return {
    id: fieldMetadata.id,
    label: fieldMetadata.label,
    fieldType: fieldTypeToFormFieldType[fieldMetadata.fieldType]
  } as FormFieldMetadata;
};

export const getFormGeneratorFieldRules = (fieldMetadata: FormFieldMetadata): Rule[] => [
  {
    required: fieldMetadata.required,
    message: translate('common:required-field', { fieldName: fieldMetadata.label || '' })
  },
  ...(fieldMetadata.rules || [])
];

export const getFormGenratorFieldTestId = (fieldId: string): string =>
  `form-generator-${camelCaseToKebabCase(fieldId)}`;

export const getFormGeneratorFieldPathName = (fieldMetadata: FormFieldMetadata): string[] =>
  fieldMetadata.dataPath || fieldMetadata.id.toString().split('.');
