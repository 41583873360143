import styled from '@emotion/styled';
import { StyledWidgetHeaderWrapper } from 'Components/Styled/WidgetHeader';
import { ColorPalette, Colors, times8 } from 'Constants/Styles';
import { getColorWithBrightness } from 'Utils/colors';

const TABLE_SELECT_WIDTH = 52;
const TABLE_LIGHT_GREY = '#f9f9f9';

export const StyledTable = styled.div<{ withRowSelect?: boolean }>`
  position: relative;
  .table-wrapper {
    position: relative;
    max-width: 100%;
    overflow: auto;
  }
  table {
    width: max-content;
    min-width: 100%;
    table-layout: auto;
    thead {
      position: relative;
      z-index: 5;
      tr:first-of-type > *:first-of-type {
        border-start-start-radius: ${times8()}px;
      }
      tr:first-of-type > *:last-of-type {
        border-start-end-radius: ${times8()}px;
      }
    }
    .table-body-row:nth-of-type(odd) td {
      background-color: ${TABLE_LIGHT_GREY};
    }
    .table-body-row:nth-of-type(even) td {
      background-color: white;
    }
    th,
    td {
      text-align: left;
      font-weight: normal;
      border-bottom: 1px solid ${Colors.Border};

      &.expand-content-cell,
      &.pinned-left,
      &.pinned-right {
        position: sticky;
        z-index: 1;
      }
      &.expand-content-cell,
      &.pinned-left {
        left: ${({ withRowSelect }) => (withRowSelect ? TABLE_SELECT_WIDTH : 0)}px;
      }
      &.pinned-right {
        width: 0;
        right: 0;
      }
      &.expand-content-cell {
        display: block;
        width: 0;
        padding: ${times8(4)}px;
      }
      &.row-select-cell {
        width: ${TABLE_SELECT_WIDTH}px;
        left: 0;
      }
    }
    th {
      white-space: nowrap;
      background-color: ${TABLE_LIGHT_GREY};
      border-right: 1px solid ${Colors.Border};
      box-shadow: inset 0px -1px 0px 0px #e6e7e8;
      &:hover {
        color: inherit;
      }
      &:last-of-type {
        border-right: none;
      }
      &.sortable {
        cursor: pointer;
        &:hover {
          background-color: ${ColorPalette.plainGray20};
        }
      }
    }
    td {
      padding: ${times8(1.5)}px ${times8(2)}px;
    }
    td .table-cell-wrapper {
      display: flex;
    }
    .header-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: ${times8(1.5)}px ${times8(2)}px;
      .draggable-icon {
        cursor: move;
        color: ${ColorPalette.plainGray30};
        margin-right: ${times8(0.5)}px;
        display: inline-block;
        vertical-align: middle;
        &:hover {
          color: ${ColorPalette.plainGray70};
        }
      }
      .sort-icon {
        margin-left: ${times8(2)}px;
        display: inline-block;
        vertical-align: middle;
      }
      .remove-col-button {
        margin-left: ${times8()}px;
        font-size: 10px;
        vertical-align: middle;
        .close-icon {
          color: ${ColorPalette.plainGray30};
          font-size: 12px;
        }
        &:hover .close-icon {
          color: ${ColorPalette.plainGray70};
        }
      }
    }
    .expand-row-button {
      display: inline-block;
      color: ${ColorPalette.plainGray50};
      cursor: pointer;
      margin-right: ${times8()}px;
      .expand-row-icon {
        transition: transform 0.1s ease-in-out;
        &.expanded {
          transform: rotate(90deg);
        }
      }
    }
  }
  .table-footer {
    display: flex;
    margin-top: ${times8(3)}px;
  }

  .pinned-left,
  .pinned-right {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: -1px;
      width: 30px;
      pointer-events: none;
    }
  }
  .pinned-left:after {
    right: 0;
    transform: translateX(100%);
  }
  .pinned-right:after {
    left: 0;
    transform: translateX(-100%);
    box-shadow: inset -10px 0 8px -8px rgba(5, 5, 5, 0.06);
  }

  &.scroll-hor .pinned-left:after {
    box-shadow: inset 10px 0 8px -8px rgba(5, 5, 5, 0.06);
  }
  &.scroll-hor-end .pinned-right:after {
    box-shadow: none;
  }

  &.loading {
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 200px;
      height: 60px;
      background: white;
      background: linear-gradient(150deg, rgba(255, 255, 255, 0.8) 30%, rgba(255, 255, 255, 0) 100%);
      z-index: 2;
    }
    .table-wrapper table {
      opacity: 0.5;
      filter: saturate(0.2);
    }
    .table-loading-spinner {
      position: absolute;
      top: ${times8(2)}px;
      left: ${times8(2)}px;
      z-index: 3;
    }
  }
  &.empty {
    .table-wrapper {
      height: 160px;
    }
    .empty-data-icon {
      position: absolute;
      top: 56px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  //
  table tbody tr td {
    &.highlight-negative {
      color: ${getColorWithBrightness(Colors.Negative, -0.2)};
    }
  }
`;

export const StyledTableControls = styled(StyledWidgetHeaderWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;

  .left,
  .right {
    display: flex;
    height: fit-content;
    align-items: baseline;
  }
  .right {
    flex-shrink: 0;
  }
  .left {
    flex-grow: 1;
  }

  // left
  .table-header-title,
  .table-header-prefix {
    flex-shrink: 0;
    margin-right: ${times8()}px;
  }
  .table-header-column-select {
    margin-left: ${times8()}px;
  }

  // right
  .table-header-column-select {
    margin-left: ${times8()}px;
  }
  .table-search {
    width: 200px;
    margin-left: ${times8()}px;
  }
  .table-header-suffix {
    > a,
    > button,
    > div {
      margin-left: ${times8()}px;
    }
  }
`;
