import React, { useState } from 'react';
import { RouterProvider, createBrowserRouter, useSearchParams } from 'react-router-dom';

import { isMobileApp } from 'Mobile/MobileAppUtils';
import { isIFrame } from 'Utils/url';
import { isGainsightApp } from 'Views/GainsightApp/GainsightAppUtils';
import { isSalesforceApp } from 'Views/SalesforceApp/SalesforceAppUtils';

import MobileAppEntry from 'Mobile/MobileAppEntry';
import GainsightAppEntry from 'Views/GainsightApp/GainsightAppEntry';
import SalesforceAppEntry from 'Views/SalesforceApp/SalesforceAppEntry';
import IframeAppEntry from 'Views/IframeApp/IframeAppEntry';
import DesktopAppEntry from 'Views/App/DesktopAppEntry';

const EntryApp: React.FC = () => {
  const [searchParams] = useSearchParams();

  const [mobileApp] = useState<boolean>(isMobileApp());
  const [gainsightApp] = useState<boolean>(isGainsightApp(searchParams));
  const [salesforceApp] = useState<boolean>(isSalesforceApp(searchParams));
  const [iframeApp] = useState<boolean>(isIFrame());

  if (mobileApp) return <MobileAppEntry />;
  if (gainsightApp) return <GainsightAppEntry />;
  if (salesforceApp) return <SalesforceAppEntry />;
  if (iframeApp) return <IframeAppEntry />;
  return <DesktopAppEntry />;
};

const router = createBrowserRouter([{ path: '*', Component: EntryApp }]);

const AppEntry: React.FC = () => <RouterProvider router={router} />;

export default AppEntry;
