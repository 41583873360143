import React from 'react';
import styled from '@emotion/styled';
import { ColorPalette, Colors, times8 } from 'Constants/Styles';
import classNames from 'classnames';

interface StepsProps {
  steps: StepperStep[];
  activeStep?: number;
  className?: string;
  onChange?(stepIndex: number): void;
}

interface StepperStep {
  label: string;
  key: string;
}

const StyledSteps = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto ${times8(2)}px;

  .stepper-connect-line {
    position: relative;
    flex-grow: 1;
    border-bottom: 1px solid ${Colors.Border};
    height: 0;
    margin: 0 ${times8()}px;
    top: ${times8(2)}px;
  }
`;

const StyledStepsStep = styled.div<{ hasAction: boolean }>`
  position: relative;
  width: ${times8(4)}px;
  flex-grow: 0;
  flex-shrink: 0;
  cursor: ${({ hasAction }) => (hasAction ? 'pointer' : 'auto')};

  .stepper-step-index {
    width: ${times8(4)}px;
    height: ${times8(4)}px;
    line-height: ${times8(4)}px;
    text-align: center;
    border-radius: 50%;
    margin-bottom: ${times8()}px;
    color: ${Colors.SubText};
    border: 1px solid ${Colors.SubText};
    background-color: ${ColorPalette.plainGray0};
  }

  .stepper-step-title {
    position: absolute;
    white-space: nowrap;
    transform: translateX(-50%);
    left: ${times8(2)}px;
    color: ${Colors.SubText};
  }

  &.active {
    .stepper-step-index {
      color: ${ColorPalette.plainGray0};
      background-color: ${Colors.Primary};
      border: 1px solid ${Colors.Primary};
    }
    .stepper-step-title {
      color: ${Colors.Text};
    }
  }
`;

export const Steps: React.FC<StepsProps> = ({ steps, activeStep, className, onChange }) => {
  return (
    <StyledSteps className={className}>
      {steps.map((step, index) => (
        <React.Fragment key={step.key}>
          <StyledStepsStep
            className={classNames('stepper-step', index === activeStep && 'active')}
            data-testid="stepper-step"
            onClick={() => onChange && onChange(index)}
            hasAction={!!onChange}
          >
            <div className="stepper-step-index">{index + 1}</div>
            <div className="stepper-step-title">{step.label}</div>
          </StyledStepsStep>

          {index < steps.length - 1 && <div className="stepper-connect-line" />}
        </React.Fragment>
      ))}
    </StyledSteps>
  );
};
