export enum QueryKey {
  Me = 'Me',
  Lead = 'Lead',
  FeatureFlags = 'FeatureFlags',
  CommunicationVolume = 'CommunicationVolume',
  Customer = 'Customer',
  CustomerHistory = 'CustomerHistory',
  CustomerDomainsSyncProblem = 'CustomerDomainsSyncProblem',
  Customers = 'Customers',
  CustomerChildren = 'CustomerChildren',
  CustomersOverview = 'CustomersOverview',
  CustomerSummary = 'CustomerSummary',
  CustomerTouch = 'CustomerTouch',
  EmailsSubjects = 'EmailsSubjects',
  ThreadEmails = 'ThreadEmails',
  RelationHeat = 'RelationHeat',
  Stakeholders = 'Stakeholders',
  Stakeholder = 'Stakeholder',
  StakeholdersOverview = 'StakeholdersOverview',
  StakeholderRoles = 'StakeholderRoles',
  Users = 'Users',
  UsersOverview = 'UsersOverview',
  UsersSyncStatus = 'UsersSyncStatus',
  User = 'User',
  Topics = 'Topics',
  TopicsData = 'TopicsData',
  LastTopicsData = 'LastTopicsData',
  TopicsBreakdown = 'TopicsBreakdown',
  SentimentOvertime = 'SentimentOvertime',
  Insights = 'Insights',
  CustomInsights = 'CustomInsights',
  CustomInsightsMetadata = 'CustomInsightsMetadata',
  InsightTypes = 'InsightTypes',
  InsightsSettings = 'InsightsSettings',
  InsightAcknowledge = 'InsightAcknowledge',
  Tiers = 'Tiers',
  JourneyPhase = 'JourneyPhase',
  UserStatistics = 'UserStatistics',
  CommunicationTimeline = 'CommunicationTimeline',
  CommunicationOvertime = 'CommunicationOvertime',
  CommunicationChat = 'CommunicationChat',
  CommunicationTicket = 'CommunicationTicket',
  ReportTypes = 'ReportTypes',
  ReportTemplate = 'ReportTemplate',
  ReportTemplates = 'ReportTemplates',
  ReportConfigurations = 'ReportConfigurations',
  ReportDefinitions = 'ReportDefinitions',
  Report = 'Report',
  ReportEntityMetadata = 'ReportEntityMetadata',
  CustomerBreakdownMetadata = 'CustomerBreakdownMetadata',
  CustomReportData = 'CustomReportData',
  DownloadReport = 'DownloadReport',
  EmailReport = 'EmailReport',
  CalendarEvents = 'CalendarEvents',
  LifecycleJourney = 'LifecycleJourney',
  LifecycleEventConfigurations = 'LifecycleEventConfigurations',
  DashboardMetadata = 'DashboardMetadata',
  DashboardBoards = 'DashboardBoards',
  DashboardCards = 'DashboardCards',
  DashboardSummary = 'DashboardSummary',
  Aggregations = 'Aggregations',
  DashboardActivity = 'DashboardActivity',
  DashboardHealthScore = 'DashboardHealthScore',
  DashboardInsightOvertime = 'DashboardInsightOvertime',
  VisitorCountryCode = 'VisitorCountryCode',
  Lists = 'Lists',
  Permissions = 'Permissions',
  Notifications = 'Notifications',
  NotificationsMetadata = 'NotificationsMetadata',
  NotificationChannels = 'NotificationChannels',
  HealthScoreWeights = 'HealthScoreWeights',
  HealthScoreConfig = 'HealthScoreConfig',
  HealthScoreConfigMetadata = 'HealthScoreConfigMetadata',
  SuccessScores = 'SuccessScores',
  HealthScoreOvertime = 'HealthScoreOvertime',
  UserEmailReports = 'UserEmailReports',
  QnaResponse = 'QnaResponse',
  QnaExample = 'QnaExample',
  CustomerGroups = 'CustomerGroups',
  OnbordingChecklist = 'OnbordingChecklist',
  StakeholdersLookup = 'StakeholdersLookup',
  TeamEffort = 'TeamEffort',
  FieldsMetadata = 'FieldsMetadata',
  EntityFieldsMetadata = 'EntityFieldsMetadata',
  ExtraValuesOvertime = 'ExtraValuesOvertime',
  SystemStats = 'SystemStats',
  AlertNotificationsSystem = 'AlertNotificationsSystem',
  AlertNotificationsAI = 'AlertNotificationsAI',
  WeeklyDigest = 'WeeklyDigest',
  CustomerNotes = 'CustomerNotes',
  ConvertFilterToCondition = 'ConvertFilterToCondition',
  Currency = 'Currency',
  CurrencyExchangeRates = 'CurrencyExchangeRates',
  // integrations
  Integrations = 'Integrations',
  IntegrationsLinks = 'IntegrationsLinks',
  ProviderAuthConfig = 'ProviderAuthConfig',
  ProviderChatsConfig = 'ProviderChatsConfig',
  ProviderCrmConfig = 'ProviderCrmConfig',
  ProviderTasks = 'ProviderTasks',
  ProviderProducts = 'ProviderProducts',
  DomainInstallConfig = 'DomainInstallConfig',
  OrganizationSettingsTypes = 'OrganizationSettingsTypes',
  OrganizationSettings = 'OrganizationSettings',
  CustomFieldsExternalFields = 'CustomFieldsExternalFields',
  ProviderUploadFields = 'ProviderUploadFields',
  MsTeamsWebhooks = 'MsTeamsWebhooks',
  ProviderCrmSyncCount = 'ProviderCrmSyncCount',
  JiraConfig = 'JiraConfig',
  Scim = 'Scim'
}
