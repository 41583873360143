import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'Components/Primitives';

interface BooleanSelectProps {
  value?: boolean | null;
  placeholder?: string;
  allowClear?: boolean;
  disabled?: boolean;
  className?: string;
  'data-testid'?: string;
  onChange?(e: boolean | null): void;
}

export const BooleanSelect: React.FC<BooleanSelectProps> = ({
  value,
  placeholder,
  allowClear = true,
  disabled,
  className,
  'data-testid': dataTestId,
  onChange
}) => {
  const { t } = useTranslation(['common']);
  const [selectVal, setSelectVal] = useState<string | null>(null);

  const selectOptions = useMemo(
    (): { label: string; value: string }[] => [
      { label: t('boolean-select.true'), value: 'true' },
      { label: t('boolean-select.false'), value: 'false' }
    ],
    [t]
  );

  const onSelectChange = useCallback(
    (e: string | null) => {
      onChange && onChange(typeof e === 'string' ? e === 'true' : null);
    },
    [onChange]
  );

  useEffect(() => {
    setSelectVal(typeof value === 'boolean' ? (value ? 'true' : 'false') : null);
  }, [value]);

  return (
    <Select
      data-testid={dataTestId}
      className={className}
      value={selectVal}
      mode="single"
      options={selectOptions}
      allowClear={allowClear}
      disabled={disabled}
      placeholder={placeholder || ''}
      onChange={onSelectChange}
    />
  );
};
