import { atomFamily, atom } from 'recoil';
import { GraphHighlightCoordsState, GraphHighlightFocusState } from 'Components/Graphs';
import { AppType } from 'Types/Auth.types';
import { DateRangeValue } from 'Types/DateRange.types';
import { CommunicationTimelineParams } from 'Components/CommunicationTimeline';
import { ContentGenerationModalParams } from 'Components/ContentGeneration';
import { AddLifecycleEventParams, DeleteLifecycleEventCommunicationModalParams } from 'Components/CustomEvents';

export const AppTypeAtom = atom<AppType | null>({
  key: 'appTypeAtom',
  default: null
});

export interface LayoutInfo {
  contentOffsetY: number;
}

export const LayoutAtom = atom<LayoutInfo>({
  key: 'layoutAtom',
  default: {
    contentOffsetY: 0
  }
});

export const DateRangeAtom = atom<DateRangeValue | null>({
  key: 'dateRangeAtom',
  default: null
});

export const SlidingDateRangeAtom = atom<DateRangeValue | null>({
  key: 'slidingDateRangeAtom',
  default: null
});

export const CommunicationTimelineParamsAtom = atom<CommunicationTimelineParams | null>({
  key: 'communicationTimelineParamsAtom',
  default: null
});

export const ReportCommunicationTimelineParamsAtom = atom<CommunicationTimelineParams | null>({
  key: 'reportCommunicationTimelineParamsAtom',
  default: null
});

export const ContentGenerationModalParamsAtom = atom<ContentGenerationModalParams | null>({
  key: 'contentGenerationModalParamsAtom',
  default: null
});

export const FocusTableColumnIdAtom = atom<string | null>({
  key: 'focusTableColumnIdAtom',
  default: null
});

export const GraphHighlightCoordsAtomFamily = atomFamily<GraphHighlightCoordsState, string>({
  key: 'GraphHighlightCoordsState',
  default: {
    chartDimentions: undefined,
    barsCoords: undefined,
    barWidth: undefined
  }
});

export const GraphHighlightFocusAtomFamily = atomFamily<GraphHighlightFocusState, string>({
  key: 'GraphHighlightFocusState',
  default: {
    weekInFocusIndex: null
  }
});

export const AddLifecycleEventAtom = atom<AddLifecycleEventParams | null>({
  key: 'addLifecycleEventState',
  default: null
});

export const DeleteLifecycleEventCommunicationAtom = atom<DeleteLifecycleEventCommunicationModalParams | null>({
  key: 'deleteLifecycleEventCommunicationAtom',
  default: null
});
