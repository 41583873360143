import React from 'react';
import styled from '@emotion/styled';
import classNames from 'classnames';
import { ColorPalette } from 'Constants/Styles';

interface TableSortIconProps {
  direction: 'asc' | 'desc' | undefined;
}

const StyledTableSortIcon = styled.div`
  .arrow {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    transform: scaleX(0.8);
  }
  .asc {
    border-bottom: 5px solid ${ColorPalette.plainGray40};
    margin-bottom: 3px;
    &.active {
      border-bottom-color: ${ColorPalette.royalBlue70};
    }
  }
  .desc {
    border-top: 5px solid ${ColorPalette.plainGray40};
    &.active {
      border-top-color: ${ColorPalette.royalBlue70};
    }
  }
`;

export const TableSortIcon: React.FC<TableSortIconProps> = ({ direction }) => (
  <StyledTableSortIcon className="sort-icon">
    <div className={classNames('arrow', 'asc', direction === 'asc' && 'active')} />
    <div className={classNames('arrow', 'desc', direction === 'desc' && 'active')} />
  </StyledTableSortIcon>
);
