import React, { ReactNode, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { identify } from 'Utils/analytics';
import { ErrorResponse } from 'http/Request';
import FullPageSpinner from 'Components/Styled/FullPageSpinner';
import { message } from 'Components/Primitives';
import { AuthState } from 'Types/Auth.types';
import { useAppType, useGainsightAuthVerifyMutation, useLoggedInUserQuery } from 'hooks';
import { GS_SESSION_VALIDATOR_KEY } from 'Views/GainsightApp/hooks/useGainsightAppAuth';

const UnauthContext: AuthState = {
  isAuthenticated: false,
  user: undefined,
  refreshSession: () => {}
};

const AuthContext = React.createContext<AuthState>(UnauthContext);

const AuthProvider: React.FC<{ children?: ReactNode }> = (props) => {
  const [searchParams] = useSearchParams();
  const appType = useAppType();
  const [gsSessionValidator] = useState<string | null>(searchParams.get(GS_SESSION_VALIDATOR_KEY));
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { mutate } = useGainsightAuthVerifyMutation({
    onSuccess: () => {
      refreshSession();
    },
    onError: (error: ErrorResponse) => {
      message.error(error.message);
    }
  });

  const { data: user, refetch: refreshSession } = useLoggedInUserQuery({ enabled: !gsSessionValidator });

  useEffect(() => {
    if (gsSessionValidator) {
      mutate({ gsSessionValidator: decodeURIComponent(gsSessionValidator) });
    }
  }, [gsSessionValidator, mutate]);

  useEffect(() => {
    if (user) {
      setIsLoading(false);
    }
    if (user && user.id) {
      identify(user, appType);
    }
  }, [user, appType]);

  return isLoading ? (
    <FullPageSpinner />
  ) : user && user.id ? (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated: true,
        refreshSession
      }}
      {...props}
    />
  ) : (
    <AuthContext.Provider value={UnauthContext} {...props} />
  );
};

const useAuth = (): AuthState => React.useContext(AuthContext);

export { AuthProvider, useAuth };
