import * as React from "react";
const SvgOrderedList = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "64 64 896 896", focusable: "false", "data-icon": "ordered-list", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 148.11 571.951 L 148.11 544.7 L 151.001 541.778 L 184.699 507.72 C 191.086 501.336 196.327 495.804 200.646 490.901 C 204.672 486.332 207.23 482.563 209.177 478.414 C 210.978 474.573 211.65 471.441 211.65 466.731 C 211.65 461.472 211.235 460.3 208.78 458.16 C 206.025 455.759 204.101 455.131 198.97 455.131 C 193.695 455.131 190.335 455.79 186.647 457.471 C 182.489 459.366 178.644 461.72 174.163 465.254 L 166.388 471.385 L 145.591 445.278 L 153.148 439.069 C 158.765 434.454 165.688 430.126 172.82 426.92 C 180.378 423.523 189.829 421.631 198.97 421.631 C 212.492 421.631 225.524 425.819 233.977 433.179 C 242.612 440.698 247.85 453.231 247.85 465.831 C 247.85 473.621 245.993 482.098 242.762 488.98 C 239.692 495.522 235.092 502.458 229.76 508.635 C 224.692 514.507 218.652 520.888 211.926 527.497 L 201.735 537.551 L 253.07 537.551 L 253.07 571.951 L 148.11 571.951 Z", style: {
  fill: "currentColor",
  stroke: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M 249.225 701.689 C 249.225 711.978 245.501 722.571 239.307 729.153 C 238.613 729.89 237.873 730.605 237.091 731.296 C 239.272 732.71 241.216 734.283 242.859 736.004 C 249.281 742.732 253.185 753.374 253.185 763.609 C 253.185 772.318 250.742 781.616 246.548 788.631 C 242.233 795.846 234.457 802.416 225.93 806.221 C 217.55 809.961 206.12 811.949 193.865 811.949 C 186.738 811.949 179.622 811.367 173.401 810.266 C 166.931 809.12 160.056 806.787 154.113 803.873 L 148.585 801.163 L 148.585 764.044 L 162.977 771.185 C 167.857 773.606 172.656 775.279 178.382 776.656 C 184.081 778.026 188.905 778.629 194.045 778.629 C 204.258 778.629 208.918 777.416 212.359 774.381 C 215.8 771.344 216.625 769.27 216.625 763.249 C 216.625 757.598 216.259 756.835 212.276 754.597 C 207.325 751.815 201.044 750.472 190.625 750.569 L 168.205 750.569 L 168.205 717.069 L 190.805 717.069 C 200.03 717.251 204.621 715.826 208.526 712.65 C 212.287 709.592 213.025 707.983 213.025 702.769 C 213.025 698.523 212.83 698.003 210.411 696.033 C 207.692 693.819 205.388 693.149 199.625 693.149 C 193.511 693.149 189.534 693.83 185.635 695.446 C 181.153 697.305 177.094 699.438 172.675 702.333 L 164.771 707.513 L 145.475 681.2 L 153.109 675.3 C 158.356 671.244 165.295 667.349 172.687 664.471 C 180.4 661.467 189.961 659.829 199.445 659.829 C 214.323 659.829 228.031 663.988 236.175 671.46 C 244.228 678.848 249.225 690.477 249.225 701.689 Z", style: {
  fill: "currentColor",
  stroke: "none"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "235.435 184.994 207.53 184.994 163.069 220.707 184.429 247.084 199.272 234.969 199.272 333.754 235.591 333.754", style: {
  fill: "currentColor",
  stroke: "none"
} }), /* @__PURE__ */ React.createElement("line", { style: {
  fill: "none",
  strokeWidth: 70,
  stroke: "currentColor",
  strokeLinecap: "round"
}, x1: 365.493, y1: 267.682, x2: 845.877, y2: 267.682 }), /* @__PURE__ */ React.createElement("line", { style: {
  fill: "none",
  strokeWidth: 70,
  stroke: "currentColor",
  strokeLinecap: "round"
}, x1: 365.493, y1: 498.361, x2: 845.877, y2: 498.361 }), /* @__PURE__ */ React.createElement("line", { style: {
  fill: "none",
  strokeWidth: 70,
  stroke: "currentColor",
  strokeLinecap: "round"
}, x1: 365.493, y1: 729.039, x2: 845.877, y2: 729.039 }));
export default SvgOrderedList;
