import * as React from "react";
const SvgUnderline = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "64 64 896 896", focusable: "false", "data-icon": "underline", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M 733.434 200.642 L 733.434 500.623 C 733.434 651.229 634.294 715.688 511.999 715.688 C 389.704 715.688 290.565 651.229 290.565 500.623 L 290.565 200.642", style: {
  fill: "none",
  strokeWidth: 70,
  stroke: "currentColor",
  strokeLinecap: "round"
} }), /* @__PURE__ */ React.createElement("line", { style: {
  fill: "none",
  strokeWidth: 70,
  stroke: "currentColor",
  strokeLinecap: "round"
}, x1: 240.95, y1: 823.358, x2: 783.05, y2: 823.358 }));
export default SvgUnderline;
