import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { borderRadiusLG, boxShadow, mediaQueryMax, times8 } from 'Constants/Styles';

export interface WidgetBoxProps {
  borderColor?: string | null;
  backgroundColor?: string;
}

const WidgetBoxStyled = css`
  padding: ${times8(3)}px;
  border-radius: ${borderRadiusLG}px;
  box-shadow: ${boxShadow};

  // Mobile
  ${mediaQueryMax.small} {
    padding: ${times8()}px;
  }
`;

export const WidgetBox = styled.div<WidgetBoxProps>`
  ${WidgetBoxStyled};
  background-color: ${({ backgroundColor }) => backgroundColor || 'white'};
  border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : 'none')};
`;

export const LinkBox = styled(Link)<WidgetBoxProps>`
  display: block;
  ${WidgetBoxStyled};
  background-color: ${({ backgroundColor }) => backgroundColor || 'white'};
  border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : 'none')};
  &:hover {
    text-decoration: none;
  }
`;
