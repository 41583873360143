import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { QueryKey } from 'Constants/QueryKeys.enum';
import { MutationHookOptions } from 'hooks/MutationHooks/MutationHook.types';
import { backendApi, ErrorResponse } from 'http/Request';
import {
  AuthCallbackResponse,
  AuthLoginMethod,
  AuthProvider,
  RegisterFormData,
  RegisterResponse
} from 'Types/Auth.types';

export interface AuthParams {
  code: string;
  callbackUrl?: string;
  email?: string;
  issuer?: string;
  invitationId?: number;
  isTest?: boolean;
}

interface AuthMutationOptions extends MutationHookOptions {
  invalidateQueries: boolean;
}

export const useAuthLoginMethod = (options?: MutationHookOptions) => {
  return useMutation(
    (params: { email?: string; issuer?: string; orgId?: string }) =>
      backendApi.post<AuthLoginMethod>('auth/login-method', params),
    {
      onError: (error: AxiosError<ErrorResponse>) => {
        options && options.onError(error.response?.data);
      },
      onSuccess: (response) => {
        options && options.onSuccess(response.data);
      }
    }
  );
};

export const useAuthMutation = (domain: AuthProvider, options?: AuthMutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: AuthParams) => backendApi.post<AuthCallbackResponse>(`auth/${domain.toLowerCase()}/callback`, params),
    {
      onError: (error: AxiosError<ErrorResponse>) => {
        options && options.onError(error.response?.data);
      },
      onSuccess: (response) => {
        if (options && options.invalidateQueries) {
          queryClient.invalidateQueries(QueryKey.Me);
          queryClient.invalidateQueries(QueryKey.Integrations);
        }
        options && options.onSuccess(response.data);
      }
    }
  );
};

export const useGoogleIdentifyMutation = (options?: AuthMutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation((params: AuthParams) => backendApi.post<AuthCallbackResponse>('/auth/google/verify-jwt', params), {
    onError: (error: AxiosError<ErrorResponse>) => {
      options && options.onError(error.response?.data);
    },
    onSuccess: (response) => {
      if (options && options.invalidateQueries) {
        queryClient.invalidateQueries(QueryKey.Me);
        queryClient.invalidateQueries(QueryKey.Integrations);
      }
      options && options.onSuccess(response.data);
    }
  });
};

export const useGoogleAuthMutation = (options?: AuthMutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: AuthParams) => backendApi.post<AuthCallbackResponse>('/auth/google/code-for-token', params),
    {
      onError: (error: AxiosError<ErrorResponse>) => {
        options && options.onError(error.response?.data);
      },
      onSuccess: (response) => {
        if (options && options.invalidateQueries) {
          queryClient.invalidateQueries(QueryKey.Me);
          queryClient.invalidateQueries(QueryKey.Integrations);
        }
        options && options.onSuccess(response.data);
      }
    }
  );
};

export const useRegisterOrgMutation = (options?: MutationHookOptions) => {
  const queryClient = useQueryClient();

  return useMutation((params: RegisterFormData) => backendApi.post<RegisterResponse>('/auth/register', params), {
    onError: (error: AxiosError<ErrorResponse>) => {
      options && options.onError(error.response?.data);
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries(QueryKey.Me);
      queryClient.invalidateQueries(QueryKey.Integrations);
      options && options.onSuccess(response.data);
    }
  });
};

export const useRevokeGoogleAuthMutation = (options?: MutationHookOptions) => {
  const queryClient = useQueryClient();

  return useMutation(() => backendApi.delete('/auth/google'), {
    onError: (error: AxiosError<ErrorResponse>) => {
      options && options.onError(error.response?.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.Me);
      queryClient.invalidateQueries(QueryKey.Integrations);
      options && options.onSuccess();
    }
  });
};

export const useRevokeMicrosoftAuthMutation = (options?: MutationHookOptions) => {
  const queryClient = useQueryClient();

  return useMutation(() => backendApi.delete('/auth/microsoft'), {
    onError: (error: AxiosError<ErrorResponse>) => {
      options && options.onError(error.response?.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.Me);
      queryClient.invalidateQueries(QueryKey.Integrations);
      options && options.onSuccess();
    }
  });
};

export const useSwitchOrganizationMutation = (options?: MutationHookOptions) => {
  return useMutation((orgId: number) => backendApi.post('/auth/internal/switch', { orgId }), {
    onError: (error: AxiosError<ErrorResponse>) => {
      options && options.onError(error.response?.data);
    },
    onSuccess: () => {
      options && options.onSuccess();
    }
  });
};

export const useSetDefaultOrganizationMutation = (options?: MutationHookOptions) => {
  const queryClient = useQueryClient();

  return useMutation(() => backendApi.post('/auth/internal/set-default'), {
    onError: (error: AxiosError<ErrorResponse>) => {
      options && options.onError(error.response?.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.Me);
      options && options.onSuccess();
    }
  });
};

export const useGainsightAuthMutation = (options?: AuthMutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: AuthParams) => backendApi.post<AuthCallbackResponse>('auth/gainsightsso/verify-token', params),
    {
      onError: (error: AxiosError<ErrorResponse>) => {
        options && options.onError(error.response?.data);
      },
      onSuccess: (response) => {
        if (options && options.invalidateQueries) {
          queryClient.invalidateQueries(QueryKey.Me);
        }
        options && options.onSuccess(response.data);
      }
    }
  );
};

// verify gainsight auth in iframe
interface GainsightAuthValidateParams {
  gsSessionValidator: string;
}
export const useGainsightAuthVerifyMutation = (options?: MutationHookOptions) => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: GainsightAuthValidateParams) =>
      backendApi.post<AuthCallbackResponse>('/auth/gainsightsso/verify-gs-session', params),
    {
      onError: (error: AxiosError<ErrorResponse>) => {
        options && options.onError(error.response?.data);
      },
      onSuccess: (response) => {
        queryClient.invalidateQueries(QueryKey.Me);
        options && options.onSuccess(response.data);
      }
    }
  );
};
