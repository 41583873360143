import { FilterMetadata, FilterValue } from 'Components/Filters';
import { FieldTypeData, WeekDay } from 'Types/Shared.types';
import { Provider } from 'Types/Integrations.types';
import { ConditionField, ConditionGroup } from 'Components/Conditions';
import { BucketScoreModifier } from 'Types/Customer.types';
import {
  ReportFieldTypeBase,
  ReportFieldTypeReference,
  ReportFieldTypeCollection,
  ReportFieldTypePickList,
  ReportFieldTypeFilter
} from 'Types/ReportField.types';
import { AggregationType } from 'Types/Aggregations.types';

export enum ReportColumnType {
  String = 'String',
  StringList = 'StringList',
  Number = 'Number',
  Boolean = 'Boolean',
  Date = 'Date',
  DateTime = 'DateTime',
  Sentiments = 'Sentiments',
  DurationWithTrend = 'DurationWithTrend',
  NumberWithTrend = 'NumberWithTrend',
  Integration = 'Integration',
  Customer = 'Customer',
  CustomerReference = 'CustomerReference',
  User = 'User',
  UserReference = 'UserReference',
  Stakeholder = 'Stakeholder',
  URL = 'URL',
  List = 'List',
  RetentionScore = 'RetentionScore',
  Communication = 'Communication',
  CustomerList = 'CustomerList',
  UserOrStakeholder = 'UserOrStakeholder'
}

export enum ReportType {
  GapAnalysis = 'gap_analysis',
  Insights = 'insights',
  MeetingPrep = 'meetings',
  CustomersOverview = 'customers_overview',
  UserPerformance = 'user_performance',
  Topics = 'topics',
  Events = 'events',
  DataSync = 'dataSync',
  Stakeholders = 'stakeholders',
  TeamEfficiency = 'team_efficiency',
  MissingContacts = 'missing_contacts',
  OpenItems = 'open_items',
  Relationship = 'relationship',
  ChurnAnalysis = 'churn_analysis',
  DailySummary = 'daily_summary'
}

export interface ReportFilter extends FilterMetadata {
  defaultValue: FilterValue;
}

export interface ReportColumn {
  id: string;
  dataPath: string;
  label: string;
  type: ReportColumnType;
  selectedByDefault: boolean;
  hidden: boolean;
  description?: string;
  provider?: Provider;
  renderingConfiguration?: {
    PositiveTrendDirection?: 'Up' | 'Down';
    DisplayType?: ColumnRenderDisplayType;
    HighlightNegativeAbove?: number;
    HighlightNegativeDateBefore?: string;
    ScoreModifierPath?: string;
  };
  typeData?: FieldTypeData;
}

export enum ColumnRenderDisplayType {
  Currency = 'Currency',
  Score = 'Score',
  Percentage = 'Percentage',
  CrmCustomerLink = 'CrmCustomerLink',
  CrmStakeholderLink = 'CrmStakeholderLink',
  AbsoluteDate = 'AbsoluteDate'
}

export interface ReportSpec {
  id: ReportType;
  name: string;
  columns: ReportColumn[];
  filters: ReportFilter[];
  color: string;
  isNew: boolean;
}

export interface ReportTemplate {
  id: string;
  label: string;
  color: string;
  isNew: boolean;
}

export enum ScheduleCadence {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly'
}

export interface ReportSchedule {
  recipients: number[];
  cadence: ScheduleCadence;
  dayOfWeek: WeekDay;
  dayOfMonth: number;
  hourOfDay: number;
  timezone: string; // example: "Asia/Jerusalem"
}

export interface ReportConfig {
  id: number;
  name: string;
  type: ReportType;
  columnIds: string[] | null;
  filter: object;
  sorter: {
    columnId: string;
    order: 'descend' | 'ascend' | null | undefined;
  } | null;
  schedule: ReportSchedule | null;
  isEditable: boolean;
  isShared: boolean;
  ownerUserId: number | null;
}

export interface ValueTrend {
  value: number;
  trend: number;
}

//
// custom report metadata
export type ReportEntity = 'customer' | 'user' | 'stakeholder' | 'time';
export type CustomReportEntryMetadata = CustomReportFieldGroupMetadata | CustomReportFieldMetadata;

export interface CustomReportFieldGroupMetadata {
  label?: string;
  entries: CustomReportEntryMetadata[];
}

export interface CustomReportFieldMetadata {
  id: string;
  label: string;
  longLabel: string;
  type: CustomReportFieldType;
  arguments?: CustomReportFieldArgument[];
  isFilterable: boolean;
  isColumn: boolean;
  aggregations?: AggregationType[];
  nestedFields?: CustomReportEntryMetadata[];
}
export type CustomReportFieldType =
  | ReportFieldTypeBase
  | ReportFieldTypeReference
  | ReportFieldTypeFilter
  | ReportFieldTypeCollection
  | ReportFieldTypePickList;

export interface CustomReportFieldArgument {
  id: string;
  label: string;
  type: CustomReportFieldType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue: any;
}

//
// custom report definition
export interface CustomReportDefinition {
  id: number;
  metadata: CustomReportMetadata;
  config: ReportBuilderConfig;
  schedule: ReportSchedule | null;
}
export interface CustomReportMetadata {
  name: string;
  isShared: boolean;
}

//
// config
export interface CustomReportColumn {
  key: string;
  field: ConditionField;
  label?: string | null;
}

export interface ReportBuilderConfig {
  arguments: ReportBuilderArguments | null;
  entity: ReportEntity;
  columns: CustomReportColumn[];
  filter: ConditionGroup;
  sortBy?: {
    columnKey: string;
    direction: 'Asc' | 'Desc';
  }[];
}

export interface ReportBuilderArguments {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

//
export interface CustomReportData {
  [key: string]: CustomReportValue;
}

export type CustomReportValue = {
  value: string | number | string[] | boolean | null | undefined;
  data?:
    | ReportCustomerRefData
    | ReportUserRefData
    | ReportStakeholdertRefData
    | ReportCRMLinkData
    | ReportScoreModifierData
    | ReportHighlightData
    | ReportCurrencyData;
};

export interface ReportCustomerRefData {
  icon: string | null;
  name: string;
}

export interface ReportUserRefData {
  avatar: string | null;
  fullName: string | null;
}

export interface ReportStakeholdertRefData {
  name: string;
}

export interface ReportCRMLinkData {
  crmLink: string | null;
}

export interface ReportScoreModifierData {
  healthScoreModifier?: BucketScoreModifier;
}

export interface ReportHighlightData {
  highlightBelow?: number | string;
  highlightAbove?: number | string;
}

export interface ReportCurrencyData {
  currency: string;
}
