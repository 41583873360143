import React, { ReactNode, useMemo } from 'react';
import { Empty as RefEmpty } from 'antd';
import styled from '@emotion/styled';
import { ColorPalette, times8 } from 'Constants/Styles';
import { FishInWater as FishInWaterComponent, PufferFishSwimming } from 'Components/Styled/StyledOcean';

export interface EmptyProps {
  description?: string;
  image?: EmptyImage;
  className?: string;
  'data-testid'?: string;
}

export enum EmptyImage {
  Empty = 'empty',
  PufferFish = 'pufferFish',
  FishInWater = 'fishInWater'
}

const imageSrcMap: Record<EmptyImage, ReactNode> = {
  [EmptyImage.Empty]: RefEmpty.PRESENTED_IMAGE_SIMPLE,
  [EmptyImage.PufferFish]: <PufferFishSwimming />,
  [EmptyImage.FishInWater]: <FishInWaterComponent />
};

const StyledEmpty = styled.div`
  color: ${ColorPalette.plainGray50};
  text-align: center;
  .empty-image-wrapper {
    display: inline-block;
    margin: ${times8()}px auto;
  }
`;

export const Empty: React.FC<EmptyProps> = ({ description, image, className, 'data-testid': dataTestId }) => {
  const imageNode = useMemo(() => (image ? imageSrcMap[image] : undefined), [image]);

  return (
    <StyledEmpty className={className} data-testid={dataTestId}>
      <div className="empty-image-wrapper">{imageNode}</div>
      <p>{description}</p>
    </StyledEmpty>
  );
};
