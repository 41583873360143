export const times8 = (num: number = 1) => num * 8;

export const borderRadius = 6;
export const borderRadiusLG = 16;
export const boxShadow = `0 2px 4px 0 rgba(52, 56, 58, 0.06), 0 0 1px 0 rgba(52, 56, 58, 0.24);`;

export const hexAlpha0 = '00';
export const hexAlpha03 = '08';
export const hexAlpha05 = '0d';
export const hexAlpha07 = '12';
export const hexAlpha10 = '1a';
export const hexAlpha15 = '26';
export const hexAlpha20 = '33';
export const hexAlpha25 = '40';
export const hexAlpha50 = '80';
export const hexAlpha75 = 'bf';

export const IconSize = {
  large: 24,
  medium: 18,
  small: 13
};

export const ColorPalette = {
  // royal-blue
  royalBlue90: '#034AA3',
  royalBlue80: '#035BC6',
  royalBlue70: '#0369E9',
  royalBlue60: '#3084ED',
  royalBlue50: '#62A1F1',
  royalBlue40: '#A2C7F7',
  royalBlue30: '#D2E4FB',
  royalBlue20: '#E6F0FD',
  royalBlue10: '#F5F9FE',
  royalBlue0: '#FFFFFF',

  // Fresh Green
  freshGreen90: '#0F7D4E',
  freshGreen80: '#12995B',
  freshGreen70: '#13AD68',
  freshGreen60: '#13C77F',
  freshGreen50: '#56D791',
  freshGreen40: '#98E7B1',
  freshGreen30: '#D5F5DB',
  freshGreen20: '#EBFBEE',
  freshGreen10: '#F6FDFA',
  freshGreen0: '#FFFFFF',

  // Honey Yellow
  honeyYellow90: '#C36900',
  honeyYellow80: '#DF8404',
  honeyYellow70: '#F4A702',
  honeyYellow60: '#FFBB00',
  honeyYellow50: '#FFD45C',
  honeyYellow40: '#FFE18F',
  honeyYellow30: '#FFECB8',
  honeyYellow20: '#FFF3D1',
  honeyYellow10: '#FFFAEB',
  honeyYellow0: '#FFFFFF',

  // Tart Red
  tartRed90: '#9A1C0B',
  tartRed80: '#BF210D',
  tartRed70: '#DC3626',
  tartRed60: '#F75D4F',
  tartRed50: '#F9968D',
  tartRed40: '#FCBEB9',
  tartRed30: '#FDDFDC',
  tartRed20: '#FFF2F1',
  tartRed10: '#FFF9F9',
  tartRed0: '#FFFFFF',

  // Rich Gray
  richGray90: '#181F26',
  richGray80: '#25313B',
  richGray70: '#3C4A57',
  richGray60: '#5F6C7A',
  richGray50: '#ACB4BD',
  richGray40: '#D5D9DE',
  richGray30: '#E6E9EC',
  richGray20: '#F5F7F9',
  richGray10: '#FDFDFE',
  richGray0: '#FFFFFF',

  // Overlay
  overlayEmpty: 'rgba(24, 31, 38, 0)',
  overlay50: 'rgba(24, 31, 38, 0.6)',
  overlay40: 'rgba(24, 31, 38, 0.4)',
  overlay30: 'rgba(24, 31, 38, 0.24)',
  overlay20: 'rgba(24, 31, 38, 0.16)',
  overlay10: 'rgba(24, 31, 38, 0.08)',

  // Crayola Blue
  crayolaBlue90: '#115F89',
  crayolaBlue80: '#1982BA',
  crayolaBlue70: '#1E9CDF',
  crayolaBlue60: '#43ADE5',
  crayolaBlue50: '#6BBEEB',
  crayolaBlue40: '#96D1F3',
  crayolaBlue30: '#B3DEF6',
  crayolaBlue20: '#D3ECFA',
  crayolaBlue10: '#ECF8FF',
  crayolaBlue0: '#FFFFFF',

  // Mild Blue
  mildBlue90: '#3A5474',
  mildBlue80: '#48658B',
  mildBlue70: '#5B7AA3',
  mildBlue60: '#7290B7',
  mildBlue50: '#9AAFCB',
  mildBlue40: '#ADBFD5',
  mildBlue30: '#CBD6E4',
  mildBlue20: '#E1E7EF',
  mildBlue10: '#F1F4F8',
  mildBlue0: '#FFFFFF',

  // Pacific Blue
  pacificBlue90: '#1A7381',
  pacificBlue80: '#2390A2',
  pacificBlue70: '#24A6BA',
  pacificBlue60: '#2AB5CB',
  pacificBlue50: '#58C5D5',
  pacificBlue40: '#A6E2EB',
  pacificBlue30: '#B9E8EF',
  pacificBlue20: '#D4F1F5',
  pacificBlue10: '#E9F8FA',
  pacificBlue0: '#FFFFFF',

  // Lavender Purple
  lavenderPurple90: '#6E32AE',
  lavenderPurple80: '#873ED1',
  lavenderPurple70: '#A56CDD',
  lavenderPurple60: '#BF94E9',
  lavenderPurple50: '#CEAEEF',
  lavenderPurple40: '#DAC0F2',
  lavenderPurple30: '#E4D2F6',
  lavenderPurple20: '#F0E5FA',
  lavenderPurple10: '#F9F5FD',
  lavenderPurple0: '#FFFFFF',

  // Pumpkin Orange
  pumpkinOrange90: '#A04406',
  pumpkinOrange80: '#D75D0A',
  pumpkinOrange70: '#FA6B0B',
  pumpkinOrange60: '#FF7E2A',
  pumpkinOrange50: '#FF9046',
  pumpkinOrange40: '#FFAD76',
  pumpkinOrange30: '#FECCAB',
  pumpkinOrange20: '#FFE5D2',
  pumpkinOrange10: '#FFF4ED',
  pumpkinOrange0: '#FFFFFF',

  // Bell Blue
  bellBlue90: '#3A47B8',
  bellBlue80: '#4E5BCA',
  bellBlue70: '#6B75CD',
  bellBlue60: '#8C94DA',
  bellBlue50: '#A5ABE4',
  bellBlue40: '#B8BDEB',
  bellBlue30: '#CED1F2',
  bellBlue20: '#EBECFA',
  bellBlue10: '#F3F4FC',
  bellBlue0: '#FFFFFF',

  // Rose Pink
  rosePink90: '#D01759',
  rosePink80: '#EA3477',
  rosePink70: '#F5508B',
  rosePink60: '#FD7CAB',
  rosePink50: '#FF98BD',
  rosePink40: '#FFB7D2',
  rosePink30: '#FFCEE0',
  rosePink20: '#FFE6EF',
  rosePink10: '#FFF3F8',
  rosePink0: '#FFFFFF',

  // Sea Green
  seaGreen90: '#1D6D66',
  seaGreen80: '#21877E',
  seaGreen70: '#25A094',
  seaGreen60: '#41B5A9',
  seaGreen50: '#68CAC1',
  seaGreen40: '#9ADBD5',
  seaGreen30: '#BFE8E4',
  seaGreen20: '#D4F0ED',
  seaGreen10: '#ECF9F7',
  seaGreen0: '#FFFFFF',

  // Bronze Brown
  bronzeBrown90: '#6A4A3F',
  bronzeBrown80: '#7C584A',
  bronzeBrown70: '#956F61',
  bronzeBrown60: '#B08A7C',
  bronzeBrown50: '#C2A399',
  bronzeBrown40: '#D6C1BA',
  bronzeBrown30: '#E2D4CF',
  bronzeBrown20: '#EEE5E2',
  bronzeBrown10: '#F5F0EE',
  bronzeBrown0: '#FFFFFF',

  // Yellow Green
  yellowGreen90: '#517723',
  yellowGreen80: '#679530',
  yellowGreen70: '#77AB37',
  yellowGreen60: '#8AC048',
  yellowGreen50: '#A2CF6B',
  yellowGreen40: '#B8DB90',
  yellowGreen30: '#CEE6B2',
  yellowGreen20: '#E2F0D1',
  yellowGreen10: '#F5FAF0',
  yellowGreen0: '#FFFFFF',

  // Jet Grey
  jetGrey90: '#484747',
  jetGrey80: '#575555',
  jetGrey70: '#6D6A6A',
  jetGrey60: '#828080',
  jetGrey50: '#989595',
  jetGrey40: '#AEACAC',
  jetGrey30: '#C7C7C7',
  jetGrey20: '#E8E8E8',
  jetGrey10: '#F5F5F5',
  jetGrey0: '#FFFFFF',

  // Superiority Blue
  superiorityBlue90: '#34657D',
  superiorityBlue80: '#417A97',
  superiorityBlue70: '#508FAE',
  superiorityBlue60: '#69A3BF',
  superiorityBlue50: '#7FB1C9',
  superiorityBlue40: '#9FC6D7',
  superiorityBlue30: '#BDD7E4',
  superiorityBlue20: '#DDEAF1',
  superiorityBlue10: '#EFF5F8',
  superiorityBlue0: '#FFFFFF',

  // Plain Gray
  plainGray90: '#34383A',
  plainGray80: '#43474A',
  plainGray70: '#54585A',
  plainGray60: '#757777',
  plainGray50: '#959898',
  plainGray40: '#B0B2B2',
  plainGray30: '#C8C9C9',
  plainGray20: '#E3E4E4',
  plainGray10: '#F6F6F6',
  plainGray0: '#FFFFFF'
};

export const ChartColors = [
  ColorPalette.royalBlue70,
  ColorPalette.pumpkinOrange70,
  ColorPalette.lavenderPurple80,
  ColorPalette.jetGrey60,
  ColorPalette.rosePink60,
  ColorPalette.yellowGreen50,
  ColorPalette.pacificBlue80,
  ColorPalette.seaGreen90
];

export const AvatarBackgroundColors = [
  ColorPalette.freshGreen60,
  ColorPalette.pumpkinOrange40,
  ColorPalette.freshGreen50,
  ColorPalette.pumpkinOrange50,
  ColorPalette.tartRed60,
  ColorPalette.bronzeBrown90,
  ColorPalette.crayolaBlue60,
  ColorPalette.royalBlue80,
  ColorPalette.lavenderPurple90
];

export const Colors = {
  Primary: ColorPalette.royalBlue70,
  Text: ColorPalette.richGray90,
  SubText: ColorPalette.richGray60,
  Positive: ColorPalette.freshGreen70,
  Neutral: ColorPalette.honeyYellow60,
  Negative: ColorPalette.tartRed70,
  Warning: ColorPalette.pumpkinOrange70,
  Border: ColorPalette.plainGray20
};

export const Layout = {
  pagePadding: 32,
  sideBarWidth: 60,
  topBarHeight: 60,
  backgroundColor: '#f8f9fa'
};

export enum Breakpoint {
  XSmall = 'xSmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  XLarge = 'xLarge',
  XXLarge = 'xxLarge'
}
export const breakpoints: Record<Breakpoint, number> = {
  [Breakpoint.XSmall]: 576,
  [Breakpoint.Small]: 768,
  [Breakpoint.Medium]: 992,
  [Breakpoint.Large]: 1200,
  [Breakpoint.XLarge]: 1360,
  [Breakpoint.XXLarge]: 1500
};

export const mediaQueryMin: Record<Breakpoint, string> = Object.values(Breakpoint).reduce(
  (acc: Record<Breakpoint, string>, key) => {
    acc[key] = `@media (min-width: ${breakpoints[key]}px)`;
    return acc;
  },
  {
    [Breakpoint.XSmall]: '',
    [Breakpoint.Small]: '',
    [Breakpoint.Medium]: '',
    [Breakpoint.Large]: '',
    [Breakpoint.XLarge]: '',
    [Breakpoint.XXLarge]: ''
  }
);

export const mediaQueryMax: Record<Breakpoint, string> = Object.values(Breakpoint).reduce(
  (acc: Record<Breakpoint, string>, key) => {
    acc[key] = `@media (max-width: ${breakpoints[key]}px)`;
    return acc;
  },
  {
    [Breakpoint.XSmall]: '',
    [Breakpoint.Small]: '',
    [Breakpoint.Medium]: '',
    [Breakpoint.Large]: '',
    [Breakpoint.XLarge]: '',
    [Breakpoint.XXLarge]: ''
  }
);
