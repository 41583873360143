import { message } from 'Components/Primitives';
import { useGainsightAuthVerifyMutation } from 'hooks';
import { ErrorResponse } from 'http/Request';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AuthCallbackResponse, LoggedInUser } from 'Types/Auth.types';

export const GS_SESSION_VALIDATOR_KEY = 'x-gs-session-validator';

const useGainsightAuthUser = (): { isAuthenticated: boolean | undefined; user: LoggedInUser | null } => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [searchParams] = useSearchParams();
  const [user, setUser] = useState<LoggedInUser | null>(null);
  const [gsSessionValidator] = useState<string | null>(searchParams.get(GS_SESSION_VALIDATOR_KEY));

  const { mutate } = useGainsightAuthVerifyMutation({
    onSuccess: (data: AuthCallbackResponse) => {
      console.log('gainsight veryify data', data);
      setUser(data.user);
      setIsAuthenticated(true);
    },
    onError: (error: ErrorResponse) => {
      message.error(error.message);
      setIsAuthenticated(false);
    }
  });

  useEffect(() => {
    if (gsSessionValidator) {
      mutate({ gsSessionValidator: decodeURIComponent(gsSessionValidator) });
    } else {
      console.error('No x-gs-session-validator found in the url');
    }
  }, [gsSessionValidator, mutate]);

  const res = useMemo(() => ({ isAuthenticated, user }), [isAuthenticated, user]);

  return res;
};

export default useGainsightAuthUser;
