import { QueryKey } from 'Constants/QueryKeys.enum';
import { backendApi } from 'http/Request';
import { QueryObserverOptions, useQuery } from 'react-query';
import { LoggedInUser } from 'Types/Auth.types';

export const useLoggedInUserQuery = (options?: QueryObserverOptions<LoggedInUser>) => {
  return useQuery<LoggedInUser>(
    [QueryKey.Me],
    () => backendApi.get<LoggedInUser>('/users/me').then((response) => response.data),
    {
      retry: false,
      staleTime: Infinity,
      ...options
    }
  );
};
