import React, { ReactNode, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';
import { StyledSalesforceApp } from 'Views/SalesforceApp/StyledSalesforceApp';
import { ErrorPage } from 'Views/ErrorPage/ErrorPage';
import { useSearchParams } from 'react-router-dom';
import { SalesforceQueryParams } from 'Views/SalesforceApp/SalesforceApp.types';

export type SalesforceUnauthorisedErrorType = 'unauthorised' | 'accountDoesNotExist' | 'missingParams';

const StyledSalesforceUnauthorisedError = styled(StyledSalesforceApp)`
  main {
    padding: 0;
  }
  .error-page {
    height: 100vh;
  }
`;

const SalesforceUnauthorisedError: React.FC<{ type: SalesforceUnauthorisedErrorType }> = ({ type }) => {
  const { t } = useTranslation(['layout']);
  const [searchParams] = useSearchParams();
  const [params] = useState<SalesforceQueryParams>({
    sessionId: searchParams.get('sessionId'),
    orgId: searchParams.get('orgId'),
    userId: searchParams.get('userId'),
    accountId: searchParams.get('accountId')
  });

  const title = useMemo((): string => {
    switch (type) {
      case 'unauthorised':
        return t('crm.unauthorised-title');
      case 'missingParams':
        return t('crm.missing-params-title');
      case 'accountDoesNotExist':
        return t('crm.account-does-not-exist-title');
    }
  }, [t, type]);

  const subtitle = useMemo((): ReactNode | null => {
    switch (type) {
      case 'unauthorised':
        return <Trans i18nKey="layout:crm.unauthorised-subtitle" />;
      case 'missingParams':
        return (
          <Trans
            i18nKey="layout:crm.missing-params-subtitle"
            values={{
              missingUrlParams: Object.entries(params)
                .filter((entry) => entry[1] === null)
                .map((entry) => entry[0])
                .join(', ')
            }}
          />
        );
      case 'accountDoesNotExist':
        return null;
    }
  }, [type, params]);

  return (
    <StyledSalesforceUnauthorisedError>
      <main>
        <ErrorPage
          data-testid={`crm-error-${type}`}
          className="error-page"
          pageMode="inApp"
          title={title}
          subtitle={subtitle && <p>{subtitle}</p>}
          bubbleProps={{
            numberOfBubbles: 10,
            maxBubbleSize: 25
          }}
        />
      </main>
    </StyledSalesforceUnauthorisedError>
  );
};

export default SalesforceUnauthorisedError;
