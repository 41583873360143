import React, { KeyboardEvent, MouseEvent, ReactNode, useMemo, useRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import classNames from 'classnames';
import { ColorPalette, Colors, times8 } from 'Constants/Styles';
import { Spin } from 'Components/Primitives/Spin/Spin';
import { LinkBox, WidgetBox } from 'Components/Styled/WidgetBox';
import { CardHover } from 'Components/Styled/StyledSnippets';
import { Icons } from 'Components/Primitives/Icon/Icon.types';
import { Tooltip } from 'Components/Primitives/Tooltip/Tooltip';
import { MenuItem } from 'Components/Primitives/Menu/Menu';
import { Dropdown } from 'Components/Primitives/Dropdown/Dropdown';
import { Icon } from 'Components/Primitives/Icon/Icon';
import { DownloadDomAsImageButton, DownloadDomAsImageButtonStyle } from 'Components/DownloadDomAsImage';

export interface CardProps {
  className?: string;
  header?: ReactNode;
  content?: ReactNode;
  footer?: ReactNode;
  actions?: CardAction[];
  description?: ReactNode;
  linkTo?: string;
  downloadImageTrackName?: string;
  loading?: boolean;
  'data-testid'?: string;
}

export interface CardAction {
  key: string;
  label: string;
  icon: Icons;
  disabled?: boolean;
  onClick(): void;
}

const StyledCard = css`
  position: relative;
  padding: ${times8(2)}px;
  display: flex;
  flex-direction: column;

  .card-header {
    margin: -${times8(2)}px -${times8(2)}px 0;
  }
  .card-body {
    margin: ${times8()}px 0 ${times8(2)}px;
  }
  .card-footer {
    margin-top: auto;
    padding-top: ${times8(2)}px;
    border-top: 1px solid ${Colors.Border};
  }
  .card-actions,
  .tooltip-trigger {
    z-index: 1;
    position: absolute;
    top: ${times8()}px;
  }
  .card-actions {
    right: ${times8()}px;
  }
  .tooltip-trigger {
    padding: ${times8(0.5)}px;
    left: ${times8()}px;
    color: ${ColorPalette.plainGray50};
    &:hover {
      color: ${ColorPalette.plainGray60};
    }
  }
  .card-actions-menu-trigger {
    width: ${times8(3)}px;
    height: ${times8(3)}px;
    font-size: 16px;
  }
  &.loading {
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: white;
      background: linear-gradient(150deg, rgba(255, 255, 255, 0.8) 30%, rgba(255, 255, 255, 0) 100%);
      z-index: 2;
    }
    .loading-spinner {
      position: absolute;
      top: ${times8(1.5)}px;
      left: ${times8(1.5)}px;
      z-index: 3;
    }
  }
  ${DownloadDomAsImageButtonStyle};
  .download-widget-as-image-button {
    right: ${times8()}px;
    bottom: ${times8()}px;
  }
`;

const StyledDiv = styled(WidgetBox)`
  ${StyledCard};
`;
const StyledLink = styled(LinkBox)`
  color: inherit;
  ${StyledCard};
  ${CardHover};
`;

const stopPropogation = (e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => {
  e.preventDefault();
  e.stopPropagation();
};

const CardActionMenu: React.FC<{ actions: CardAction[] }> = ({ actions }) => {
  const menuItems = useMemo(
    (): MenuItem[] =>
      actions.map((action) => {
        return {
          key: action.key,
          icon: <Icon icon={action.icon} />,
          label: <span>{action.label}</span>,
          disabled: action.disabled,
          onClick: (e) => {
            stopPropogation(e.domEvent);
            action.onClick();
          }
        };
      }),
    [actions]
  );

  return (
    <Dropdown
      trigger={['click']}
      arrow={true}
      placement="bottomRight"
      align={{ offset: [8, 8] }}
      overlayStyle={{ minWidth: 120 }}
      menu={{ items: menuItems }}
    >
      <button className="card-actions-menu-trigger" data-testid="card-actions-menu-trigger" onClick={stopPropogation}>
        <Icon icon={Icons.MoreVertical} />
      </button>
    </Dropdown>
  );
};

const CardContent: React.FC<CardProps> = ({ header, content, footer, actions, description }) => {
  return (
    <>
      <div className="card-header">{header}</div>
      <div className="card-body">{content}</div>
      {footer && <div className="card-footer">{footer}</div>}
      {description && (
        <Tooltip overlayStyle={{ maxWidth: 400 }} title={description}>
          <span className="tooltip-trigger">
            <Icon icon={Icons.InfoSquare} />
          </span>
        </Tooltip>
      )}
      {actions && actions.length > 0 && (
        <div className="card-actions">
          <CardActionMenu actions={actions} />
        </div>
      )}
    </>
  );
};

export const Card: React.FC<CardProps> = (props) => {
  const { className, linkTo, loading, downloadImageTrackName, 'data-testid': dataTestId } = props;
  const domLinkRef = useRef<HTMLAnchorElement>(null);
  const domDivRef = useRef<HTMLDivElement>(null);

  return typeof linkTo === 'string' ? (
    <StyledLink
      to={linkTo}
      className={classNames(className, loading && 'loading')}
      data-testid={dataTestId}
      ref={domLinkRef}
    >
      <CardContent {...props} />
      {loading && <Spin className="loading-spinner" />}
      {domLinkRef.current && downloadImageTrackName && (
        <DownloadDomAsImageButton element={domLinkRef.current} trackName={downloadImageTrackName} />
      )}
    </StyledLink>
  ) : (
    <StyledDiv className={classNames(className, loading && 'loading')} data-testid={dataTestId} ref={domDivRef}>
      <CardContent {...props} />
      {loading && <Spin className="loading-spinner" />}
      {domDivRef.current && downloadImageTrackName && (
        <DownloadDomAsImageButton element={domDivRef.current} trackName={downloadImageTrackName} />
      )}
    </StyledDiv>
  );
};
