import { GainsightEntry } from 'Providers/Gainsight';
import { isIFrame } from 'Utils/url';
import { GS_SESSION_VALIDATOR_KEY } from 'Views/GainsightApp/hooks/useGainsightAppAuth';

export const isGainsightApp = (searchParams: URLSearchParams): boolean =>
  isIFrame() && typeof searchParams.get(GS_SESSION_VALIDATOR_KEY) === 'string';

const ENTRIES_WITH_PAGE_TOP_HEADER: GainsightEntry[] = [
  GainsightEntry.Dashboard,
  GainsightEntry.Customer,
  GainsightEntry.User,
  GainsightEntry.Stakeholder
];
export const shouldShowPageTopHeader = (entry: GainsightEntry | undefined): boolean =>
  entry ? ENTRIES_WITH_PAGE_TOP_HEADER.includes(entry) : false;
